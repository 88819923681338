@import './_variables.scss';

@mixin headline {
  font-family: "Publico-Headline-Web-Bold-Regular";
}

@mixin regular {
	font-family: "SourceSans3-Bold";
}

@mixin semiBold {
  font-family: "SourceSans3-SemiBold";
}

@mixin text {
  font-family: "SourceSans3-Regular";
}

@mixin transition {
  transition: all .3s ease-in-out;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}