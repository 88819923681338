@import '../../styles/mixins';

.container {
  position: relative;
  width: 100%;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 35px;
    margin: 0px 5px;
    button {
      @include flex-center;
      background-color: transparent;
      border: none;
      padding: 0px;
      cursor: pointer;
    }
    img {
      margin-left: 5px;
    }
    p {
      margin: 0px 5px;
      width: 110px;
      line-height: 10px;
      font-size: 14px;
      cursor: pointer;
    }
    .count {
      position: absolute;
      right: 26px;
      @include flex-center;
      background-color: #DB2347;
      border-radius: 20px;
      min-width: 18px;
      height: 18px;
      font-size: 11px;
      color:$white
    }
    .toggle {
      @include flex-center;
      width: 30px;
      height: 30px;
      transform: scale(1.1);
      cursor: pointer;
      position: absolute;
      right: 0px;
      img {
        margin: 0px;
        transform: scale(1.1);
        @include transition;
      }
      &.open {
        img {
          transform: scale(1.1) rotate(180deg);
        }
      }
    }
  }
  .dropdown {
    background-color: $white;
    z-index: 2;
    width: 100%;
    position: absolute;
    overflow: hidden;
    @include transition;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 6px 8px 15px -8px rgba(0,0,0,0.3);
    input {
      width: 100%;
      padding: 8px;
      border:none;
      border-top: 1px solid $bg-color;
      border-bottom: 1px solid $bg-color;
      @include text;
      outline: none;
    }
    .options {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      height: 150px;
      padding: 10px 0px;
      button {
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
        @include text;
        color: $primary-color;
        padding: 2px 15px;
        cursor: pointer;
        p {
          margin: 0px 8px;
          width: calc(100% - 20px);
          text-align: left;
        }

      }

    }
  }
}