$bg-color: #F3F1EC;
$white: #FFFFFF;
$black: #000000;
$primary-color: #00005C;
$primary-lighter: #000090;
$secondary-color: #DB2347;

@font-face {
	font-family: "Publico-Headline-Web-Bold-Regular";
	src: url("../assets/fonts/Publico-Headline-Web-Bold-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "SourceSans3-Bold";
	src: url("../assets/fonts/SourceSans3-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "SourceSans3-SemiBold";
	src: url("../assets/fonts/SourceSans3-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "SourceSans3-Regular";
	src: url("../assets/fonts/SourceSans3-Regular.ttf") format("truetype");
}
