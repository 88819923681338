@import './variables';
@import './mixins';

body {
  margin: 0;
  font-family: "SourceSans3-Bold",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  color: $primary-color;
  overflow: hidden;
}

.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}