@import '../../styles/mixins';

.container {
  position: fixed;
  width: 280px;
  z-index: 2;
  border-radius: 9px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.20);
  border: 1px solid rgba($primary-color, 0.8);
  background-color: $white;
  @include transition;
  color: $black;
  pointer-events: none;
  p {
    margin: 0px;
  }
  .content {
    padding: 0px 18px 18px 18px;
    h2 {
      @include headline;
      font-size: 18px;
      line-height: 18px;
      color: $primary-color;
    }
    .row {
      display: flex;
      .col {
        width: 60%;
        &:first-child {
          border-right: 1px solid #d9d8d8;
        }
        &:last-child {
          width: 40%;
          p {
            color: $primary-color;
            font-size: 13px;
            line-height: 16px;
            margin: 0px 0px 8px 10px;
            &:last-child {
              margin: 0px 0px 0px 10px;
            }
          }
        }
      }
    }
  }
}