@import '../../styles/mixins';

.timeline{
  background-color: rgba($bg-color, 0.6);
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 120px;
  right: 20px;
  padding: 30px 10px;
  z-index: 1;
  width: 150px;
  height: calc(100vh - 280px);
  border-radius: 10px;
  .slider {
    position: relative;
    height: 100%;
    .labels {
      height: calc(100% + 7.5px);
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      top: -4px;
      left: -81px;
      pointer-events: none;
      .label {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: none;
        border: none;
        background-color: transparent;
        .bar {
          width: 60px;
          height: 8px;
          display: flex;
          justify-content: flex-end;
          .value {
            @include transition;
            background-color: rgba(#9D9D9D, 0.3);
            height: 100%;
            border-radius: 4px;
          }
        }
        .puce {
          background: $white;
          z-index: 10;
          width: 12px;
          height: 12px;
          border-radius: 16px;
          margin: 0px 8px;
          border: 2px solid #9D9D9D;
          pointer-events: none;
          transform: scale(0.85);
          &:hover {
            background: #9D9D9D;
          }
        }
        p {
          @include transition;
          @include text;
          margin: 0px;
          font-size: 12px;
          color: #9D9D9D;
          pointer-events: all;
          @include semiBold;
        }
        &.active {
          .puce {
            background: $primary-color;
            border: 2px solid transparent;
          }
          .bar {
            .value {
              background-color: #BBB4D8;
            }
          }
          p {
            color: $primary-color;
          }
          &.select {
            .puce {
              background: $primary-color;
              border: 2px solid #BBB4D8;
              transform: scale(1.45);
            }
          }
        }
      }
    }
  }

}