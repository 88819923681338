@import '../../styles/mixins';

.container {
  position: relative;
  width: 100%;
  border-radius: 5px;
  background-color: $white;
  margin-bottom: 20px;
  &.d-none {
    display: none;
  }
  .title {
    border-bottom: 1px solid #e1e1e1;
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    .label {
      display: flex;
      img {
        margin: 0px 6px;
        transform: scale(1.3)
      }
      h2 {
        @include headline;
        font-size: 16px;
        margin: 0px;
      }
    }
    .toggles {
      @include flex-center;
      button {
        @include flex-center;
        cursor: pointer;
        padding: 0px 2px;
        font-size: 13px;
        background-color: transparent;
        border: none;
        color: #9697BE;
        &.disabled {
          opacity: 0.4;
        }
      }
    }
  }
  .content {
    width: 100%;
    @include transition;
    overflow: hidden;
    .tops {
      width: calc(260px * 3);
      @include transition;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .top {
        width: 260px;
        padding: 5px 0px;
        .item {
          display: flex;
          justify-content: flex-start;
          p {
            margin: 6px 8px;
            width: 10%;
            text-align: right;
            opacity: 0.4;
            &:last-child {
              opacity: 1;
              width: 82%;
              text-align: left;
            }
          }
        }
      }
    }
  }
}