@import '../../styles/mixins';

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  canvas {
    display: none;
  }
  .export {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
    top: 25px;
    border: none;
    background-color: #000090;
    border-radius: 3px;
    color: $white;
    @include regular;
    text-transform: uppercase;
    padding: 8px 10px;
    cursor: pointer;
    &:hover {
      background-color: #00005c;
    }
  }
  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    pointer-events: none;
  }
  svg {
    position: absolute;
    overflow: hidden;
    @include transition;
    text {
      &::before {
        background-color: $white;
      }
    }
  }
}