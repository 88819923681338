@import '../../styles/mixins';

.container {
  overflow: hidden;
  position: relative;
  height: 100vh;
  .logo {
    margin: 20px;
    width: 40px;
    margin-bottom: 20px;
  }
  .skip {
    background-color: transparent;
    @include regular;
    border: none;
    display: block;
    position: absolute;
    padding: 0px;
    top: 20px;
    right: 20px;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color : $primary-color;
    svg {
      margin-left: 10px;
      font-size: 20px;
    }
  }
  .seeAll {
    background-color: white;
    @include headline;
    border: none;
    display: block;
    position: absolute;
    padding: 10px 14px;
    top: 20px;
    right: 20px;
    font-size: 16px;
    border-radius: 5px;
    text-decoration: none;
    border: 1px solid $primary-color;
    display: flex;
    align-items: center;
    color : $primary-color;
    @include transition;
    &:hover {
      background-color: rgba($primary-color, 0.08);
    }
    svg {
      margin-left: 10px;
      font-size: 20px;
    }
  }
  .title {
    position: absolute;
    top: 28vh;
    left: 10vw;
    width: 590px;
    z-index: 10;
    h1, h2 {
      @include headline;
      font-size: 48px;
      margin: 0px;
    }
    h2 {
      margin-top: 50px;
      text-align: left;
    }
    .search {
      width: 740px;
      input {
        background-color: transparent;
        width: calc(100% - 8px);
        outline: none;
        border: none;
        @include headline;
        font-size: 48px;
        color: $primary-color;
        border-bottom: 1px solid rgba($primary-color, 0.2);
        &::placeholder {
          color: rgba($primary-color, 0.2);
        }
      }
      .options {
        position: absolute;
        width: 100%;
        background-color: $white;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        .option {
          padding: 20px 20px 10px 20px;
          width: 100%;
          span {
            @include text;
            font-size: 12px;
            color: #999999;
            text-transform: uppercase;
          }
          button {
            background-color: transparent;
            padding: 0px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border: none;
            @include regular;
            font-size: 16px;
            color: $primary-color;
            text-align: left;
            margin: 0px;
            width: 90%;
            word-break: keep-all;
            text-overflow: ellipsis;
            svg {
              margin-right: 8px;
            }
          }
        }
      }
      .results {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          background-color: $primary-color;
          padding: 12px 14px;
          color: $white;
          @include headline;
          margin: 5px 5px 0px 0px;
          border-radius: 5px;
          cursor: pointer;
          svg {
            margin-left: 10px;
          }
        }
        .selected {
          width: calc(100% - 210px);
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          button {
            display: flex;
            align-items: center;
            background-color: $primary-color;
            color: $white;
            border: none;
            @include semiBold;
            padding: 5px;
            padding: 5px 10px;
            margin: 5px 5px 0px 0px;
            border-radius: 5px;
            cursor: pointer;
            svg {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  .risks {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    width: 80vw;
    margin: 0px 10vw 15vh 10vw;
    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .risk {
        @include transition;
        cursor: pointer;
        box-sizing: border-box;
        width: calc(25% - 20px);
        border-radius: 5px;
        background-color: transparent;
        text-align: left;
        padding: 20px;
        opacity: 1;
        img {
          transform: translate(-10px, -8px);
        }
        h3 {
          @include headline;
          color: $primary-color;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          margin: 0px 0px 8px 0px;
        }
      }
    }
  }

}
