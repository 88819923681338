@import '../../styles/mixins';

.range-slider[data-vertical] {
  height: 100%;
  width: 2px;
  background: #9D9D9D;
}

.range-slider .range-slider__thumb {
  background: $primary-color;
  width: 12px;
  height: 12px;
  border: 2px solid #BBB4D8;
  &:hover {
    background: rgba($white, 0.8);
  }
}

.range-slider .range-slider__range {
  background: $primary-color;
}