@import '../../styles/mixins';

.container {
  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    display: block;
    width: 40px;
    margin-bottom: 20px;
    z-index: 1;
    padding: 0px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  .filters-container {
    position: absolute;
    width: 100vw;
    top: 25px;
    .content {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: auto;
      z-index: 2;
      .map {
        display: flex;
        margin-right: 8px;
        button {
          background-color: $white;
          border: none;
          height: 35px;
          width: 35px;
          @include flex-center;
          cursor: pointer;
          &:hover {
            background-color: rgba($primary-color,0.3);
          }
          &.active {
            background-color: $primary-color;
          }
          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
      .filters {
        border-radius: 5px;
        padding: 0px 2px;
        width: calc(100% - 100px);
        background-color: $white;
        display: flex;
        justify-content: space-between;
        .col {
          width: 20%;
          &:not(:last-child) {
            border-right: 1px solid $bg-color;
          }
        }
      }
    }
  }
  .aside {
    position: absolute;
    top: 60px;
    padding: 20px;
    width: 260px;
    z-index: 1;
    .risks {
      position: relative;
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      margin-bottom: 20px;
      h2 {
        @include headline;
        font-size: 16px;
        padding: 10px;
        margin: 0px;
        border-bottom: 1px solid #e1e1e1;
      }
      .row {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        .col {
          width: 50%;
        }
      }
    }
  }
}

.stat {
  display: flex;
  justify-content: flex-start;
  padding: 6px 10px;
  &.country {
    padding: 0px 10px 6px 0px;
    &:last-child {
      padding-bottom: 0px;
    }
    .label {
      display: none;
    }
    .bar-container {
      width: 100%;
      p {
        position: absolute;
      }
    }
  } 
  &:last-child {
    padding-bottom: 10px;
  }
  .label {
    width: 40%;
    margin: 0px;
    font-size: 12px;
    text-align: right;
    padding-right: 10px;
  }
  .bar-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
    .bar {
      width: 80%;
      @include transition;
    }
    p {
      width: 20%;
      margin: 0px 0px 0px 6px;
      font-size: 12px;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  min-height: 24px;
  cursor: pointer;
  input {
    display: none;
  }
  .check {
    width: 13px;
    height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #d3d3d3;
    svg {
      color: $white;
    }
    &.checked {
      background-color: $secondary-color;
      border: 1px solid $secondary-color;
      svg {
        color: $white;
      }
    }
  }
  .icon {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
  p {
    margin: 0px;
    margin-left: 5px;
    font-size: 14px;
  }
}