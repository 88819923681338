@import '../../../styles/mixins';

.element {
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    @include headline;
    font-size: 45px;
    color: $white;
    text-align: center;
  }
  img {
    transform: scale(1.2);
  }
}