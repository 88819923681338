@import '../../styles/mixins';

.container {
  position: fixed;
  width: 280px;
  min-height: 200px;
  z-index: 2;
  border-radius: 9px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.20);
  border: 2px solid rgba(0, 0, 92, 0.08);
  @include transition;
  color: $white;
  pointer-events: none;
  p {
    margin: 0px;
  }
  .content {
    padding: 0px 18px 18px 18px;
    .topics {
      margin-top: 18px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      p {
        @include text;
        font-size: 13px;
        padding: 2px 8px;
        margin: 0px 4px 4px 0px;
        border-radius: 3px;
      }
    }
    h2 {
      @include headline;
      font-size: 18px;
      line-height: 18px;
    }
    .infos {
      display: flex;
      justify-content: space-between;
      @include text;
      font-size: 14px;
      p {
        &:first-child {
          width: 80%;
        }
      }
    }
    .candidate {
      margin: 6px 0px 0px 0px;
    }
  }
}